<template>
  <v-container
    class="px-sm-10 py-sm-10 main-container"
    fluid
    style="min-height: 100vh"
  >
    <v-row>
      <v-col class="greeting-container px-8 py-12">
        <div class="txt28-white-bold">
          {{ headerTitle || 'Hi,' }}
        </div>
        <div class="txt16-white">
          {{ headerSubTitle || 'good day' }}
        </div>
      </v-col>
    </v-row>

    <template v-if="showNotif1">
      <v-row>
        <v-col class="message-container my-8">
          <div class="">
            <div class="d-flex align-center">
              <v-icon color="white">
                mdi-alert
              </v-icon>
              <span class="txt16-white-bold ml-2">
                {{ notifText || '-' }}
              </span>
            </div>
            <div class="mt-4">
              <v-btn
                v-if="notifDirection"
                color="#2E976C"
                class="text-capitalize"
                style="color: white"
                @click="nav1"
              >
                {{ notifButtonText || 'Button' }}
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </template>

    <modal-pembayaran
      :show="modal1"
      :dt-modal="dtModal"
      @submit="submit"
    />
  </v-container>
</template>

<script>
  import axios from 'axios'
  import ModalPembayaran from './pages/komponen/modal/ModalPembayaran.vue'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    name: 'Dashboard',

    components: {
      ModalPembayaran,
    },

    data: () => ({
      userInfo: JSON.parse(localStorage.getItem('userinfo')),
      headerTitle: '',
      headerSubTitle: '',
      notifText: '',
      notifButtonText: 'Lengkapi Sekarang',
      modal1: false,
      dtPayment: null,
      showNotif1: false,
      dtModal: null,
      notifDirection: 'PAY_NOW',
    }),

    created () {
      this.initialize()
      this.getDataPayment()
    },

    mounted () {
      if (this.notifDirection === 'VIEW_DATA') this.notifButtonText = 'Lengkapi Sekarang'
    },

    methods: {
      nav1 () {
        if (this.notifDirection === 'PAY_NOW') {
          this.modal1 = true
        } else {
          this.$router.push({ name: 'Pengaturan' })
        }
      },

      submit (p) {
        // console.log(p)
        if (p === 0) {
          this.modal1 = false
        } else {
          this.modal1 = false
          // this.submit()
        }
      },

      initialize () {
        const requestBody = {}
        axios.post('/v1/admin/dashboard', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.headerTitle = res.data.data.header.text_1
            this.headerSubTitle = res.data.data.header.text_2
            if (res.data.data.notification.msg_has) {
              this.showNotif1 = true
              this.notifText = res.data.data.notification.msg_content
              this.notifDirection = res.data.data.notification.msg_btn
            }
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0][0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      getDataPayment () {
        const requestBody = {}
        axios.post('/v1/payment/membership', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.dtModal = res.data.data
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0][0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      
    },
  }
</script>

<style lang="scss" scoped>
  .txt16-white {
    @extend .p-1;
    color: white;
  }

  .txt16-white-bold {
    @extend .p-1;
    color: white;
    font-weight: bold
  }

  .txt28-white-bold {
    @extend .h-4;
    color: white;
    font-weight: bold;
  }

  .main-container {
    background: $gray-95;
  }

  .greeting-container {
    background: linear-gradient(91.86deg, #36AC87 5.42%, #2B8D5F 96.41%);
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }

  .message-container {
    background: #FFC709;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }
</style>
