<template>
  <div>
    <v-dialog
      v-model="show"
      max-width="400"
      persistent
    >
      <v-card class="px-8 py-8">
        <template v-if="dtModal">
          <div class="d-flex txt16-gray50-bold">
            {{ text }}
          </div>
<!-- 
          <div class="nominal-container px-8 py-8 mt-4">
            <div class="txt16-white">
              Total Biaya Pendaftaran
            </div>

            <div class="txt28-white mt-3">
              {{ 'Rp. ' + dtModal.total | dotThousandSeparator }}
            </div>
          </div> -->

          <!-- <div class="my-4">
            <div class="txt16-gray50">
              Nomor rekening tujuan
            </div>

            <div class="txt16-gray50-bold mt-2">
              {{ dtModal.bank_account }}
            </div>
          </div> -->

          <div class="">
            <div class="txt16-gray50">
              Sertakan surat rekomendasi, yang didapat dari sekretariat 
            </div>

            <div class="txt16-gray50-bold mt-2">
              <upload-button
                class="px-0"
                text="Pilih dokumen"
                :is-loading="isLoading"
                :upload-file="uploadedFile"
                @uploadButtonClick="uploadFile"
              />
              <v-file-input
                id="upload-file"
                accept="image/*"
                style="display: none"
                @change="upload"
              />
            </div>
          </div>

          <div class="">
            <v-btn
              color="#2E976C"
              class="text-capitalize my-3"
              style="font-weight: bold; color: white"
              block
              @click="submit(1)"
            >
              Kirim
            </v-btn>
            <v-btn
              color="#CCCCCC"
              class="text-capitalize"
              style="font-weight: bold; color: white"
              block
              @click="submit(0)"
            >
              Batal
            </v-btn>
          </div>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import UploadButton from '../UploadButton.vue'
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {
      UploadButton,
    },

    props: {
      show: {
        type: Boolean,
        default: false,
      },
      text: {
        type: String,
        default: 'Surat Rekomendasi',
      },
      dtModal: {
        type: Object,
        default: null,
      },
    },

    data: () => ({
      isLoading: false,
      uploadedFile: {},
    }),

    computed: {},

    watch: {},

    created () {},

    methods: {
      async submit (p) {
        if (p === 1) {
          const res = await this.submitPayment()
          if (res === 'NOK') return
        }
        this.$emit('submit', p)
      },

      uploadFile () {
        document.getElementById('upload-file').click()
      },

      upload (e) {
        this.isLoading = true
        const baseCDNurl = process.env.VUE_APP_CDN_BASE_URL
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        const requestBody = new FormData()
        requestBody.append('file', e)
        requestBody.append('dest', 'member_photo')

        axios.post(baseCDNurl + '/v1/upload', requestBody, config).then((res) => {
          if (res.data.status === 200) {
            this.isLoading = false
            this.uploadedFile = res.data.data
            this.photoHash = res.data.data.tmp_file_hash
          }
        })
          .catch((e) => {
            this.isLoading = false
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0][0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      submitPayment () {
        return new Promise(resolve => {
          const requestBody = {
            pi_hash: this.dtModal.pi_hash,
            file_payment: this.uploadedFile.tmp_file_hash,
          }
          axios.post('/v1/payment/membership/pay-now', requestBody).then((res) => {
            if (res.data.status === 200) {
              this.$toast.success(res.data.message)
              resolve('OK')
              location.reload();
            }
          })
            .catch((e) => {
              if (typeof (e.response.data.error) === 'object') {
                this.$toast.error(Object.values(e.response.data.error)[0][0])
              } else {
                this.$toast.error(e.response.data.error)
              }
              resolve('NOK')
            })
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
.txt16-white {
  @extend .p-1;
  color: #FFFFFF;
}

.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  color: $gray-50;
  font-weight: bold;
}

.txt26-black {
  @extend .h-4;
}

.txt28-white {
  @extend .h-4;
  color: #FFFFFF;
  font-weight: bold;
}

.nominal-container {
  background: #36AC87;
  border-radius: 4px;
}
</style>
